import '@fontsource/ubuntu/300.css'
import '@fontsource/ubuntu/400.css'
import '@fontsource/ubuntu/500.css'
import '@fontsource/ubuntu/700.css'
import './src/styles/global.css'

import React from "react"
import LanguageProvider from './src/components/ui/lang/LanguageProvider'

export const wrapRootElement = ({ element }) => (
  <LanguageProvider>{element}</LanguageProvider>
)