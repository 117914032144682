import { navigate } from "gatsby";
import React, { useState } from "react";
import LanguageContext from "./LanguageContext";

const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState("en");

  const chooseLang = (chosen) => {
    let path;

    lang === "en" && chosen === "en" && (path = window.location.pathname);

    lang === "en" &&
      chosen !== "en" &&
      (path = `/${chosen}${window.location.pathname}`);

    lang !== "en" &&
      chosen === "en" &&
      (path = window.location.pathname.replace(`${lang}/`, ""));

    lang !== "en" &&
      chosen !== "en" &&
      (path = window.location.pathname.replace(lang, chosen));

    navigate(path);
  };

  const trans = (text) => {
    return text[lang];
  };

  const link = (url) => {
    if (lang === "en") {
      return url;
    } else {
      return `/${lang}${url}`;
    }
  };

  const value = {
    lang,
    setLang,
    chooseLang,
    trans,
    link,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
